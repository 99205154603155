//BUTTONS
//#region
button, .link_btn {
    font-family: $-default-font;
    transition: all .2s ease-out;
    outline: none;
    border: none;
    background: transparent;
    border-radius: 6px;

    &:active{
        transform: scale(.9);
        box-shadow: inset 5px 5px 10px #ebebeb, 
        inset -5px -5px 10px #ffffff;
    }
}
//#endregion

//TEXTBOX
//#region
input {
    font-family: $-default-font;
    border: none;
    outline: none;
}

textarea {
    font-family: $-default-font;
}
//#endregion

//TOOLTIP
//#region
[data-tooltip] {
    display: inline-block;
    position: relative;
}
/* Tooltip styling */
[data-tooltip]:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: rgba(0,0,0,.75);
    color: #fff;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: 600;
    font-family: $-default-font;
    line-height: 1.6;
    min-width: 100px;
    text-align: center;
    border-radius: 4px;
    white-space: nowrap;
}
/* Dynamic horizontal centering */
[data-tooltip-position="top"]:before,
[data-tooltip-position="bottom"]:before {
    left: 50%;
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
/* Dynamic vertical centering */
[data-tooltip-position="right"]:before,
[data-tooltip-position="left"]:before {
    top: 50%;
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
[data-tooltip-position="top"]:before {
    bottom: 100%;
    margin-bottom: 6px;
}
[data-tooltip-position="right"]:before {
    left: 100%;
    margin-left: 6px;
}
[data-tooltip-position="bottom"]:before {
    top: 100%;
    margin-top: 6px;
}
[data-tooltip-position="left"]:before {
    right: 100%;
    margin-right: 6px;
}
/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    z-index: 50;
}
//#endregion