@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Black.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Black.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Black.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Black.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Black.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Black.svg#GoogleSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Regular.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Regular.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Regular.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Regular.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Regular.svg#GoogleSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Light.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Light.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Light.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Light.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Light.svg#GoogleSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Bold.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Bold.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Bold.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Bold.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Bold.svg#GoogleSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Italic.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Italic.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Italic.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Italic.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Italic.svg#GoogleSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-BlackItalic.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-BlackItalic.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-BlackItalic.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-BlackItalic.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-BlackItalic.svg#GoogleSans-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-MediumItalic.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-MediumItalic.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-MediumItalic.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-MediumItalic.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-MediumItalic.svg#GoogleSans-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-BoldItalic.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-BoldItalic.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-BoldItalic.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-BoldItalic.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-BoldItalic.svg#GoogleSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-LightItalic.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-LightItalic.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-LightItalic.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-LightItalic.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-LightItalic.svg#GoogleSans-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-ThinItalic.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-ThinItalic.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-ThinItalic.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-ThinItalic.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-ThinItalic.svg#GoogleSans-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Medium.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Medium.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Medium.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Medium.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Medium.svg#GoogleSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Thin.eot');
    src: url('../resources/fonts/GoogleSansPlus/GoogleSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Thin.woff2') format('woff2'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Thin.woff') format('woff'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Thin.ttf') format('truetype'),
        url('../resources/fonts/GoogleSansPlus/GoogleSans-Thin.svg#GoogleSans-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

