@keyframes zoomUp {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes chatBoxShowingUp{
    0% {
        transform: scale(0);
        margin-top: 32px;
    }

    50% {
        transform: scale(1.1);
        margin-top: 16;
    }

    100% {
        transform: scale(1);
        margin-top: 16;
    }
}

@-moz-keyframes chatBoxShowingUp {
    0% {
        transform: scale(0);
        margin-top: 32px;
    }

    50% {
        transform: scale(1.1);
        margin-top: 16;
    }

    100% {
        transform: scale(1);
        margin-top: 16;
    }
}

@-webkit-keyframes chatBoxShowingUp {
    0% {
        transform: scale(0);
        margin-top: 32px;
    }

    50% {
        transform: scale(1.1);
        margin-top: 16;
    }

    100% {
        transform: scale(1);
        margin-top: 16;
    }
}