@import "../common/variables";

.card {
    transition: all .2s ease-out;
    margin: 20px;

    &.salary {
        padding: 32px;
        background-color: #fff;
        box-shadow : 0 2px 6px rgba(0,0,0, .16);
        border-radius: 14px;
        display: inline-flex;
        //align-items: center;
        flex-direction: column;
        margin-top: 32px;
        min-height: 96px;
        width: 400px;

        & hr {
            height: 1px;
            outline: none;
            width: 100%;
            background: #eaeaea;
            border: none;
            margin-top: 32px;
        }

        & h1 {
            text-align: center;
        }

        & span {
            margin-top: 16px;
            position: relative;

            &:last-child {
                color: #888;

                & span {
                    font-weight: 600;
                    font-weight: #000;
                }
            }

            & span {
                position: absolute;
                right: 0;
                top: 0;
                margin-top: 0;
                color: #000!important;
            }
        }
    }

    &.analytics {
        padding: 32px;
        background-color: #fff;
        box-shadow : 0 2px 6px rgba(0,0,0, .16);
        border-radius: 14px;
        display: flex;
        //align-items: center;
        flex-direction: row;
        margin-top: 32px;
        min-height: 96px;

        & .value_container {
            margin-right: 28px;

            & h1 {
                margin: 0;
                font-size: 20;
                font-weight: 600;
            }
    
            & p {
                margin: 0;
                margin-top: 4px;
                font-size: 12px;
                font-weight: 500;
            }

            &.con_no, &.da_chi {
                color: $-red;
            }

            &.tong {
                color: #2484FF;
            }

            &.da_thu, &.con_giu {
                color: #2484FF;
            }
        }
    }

    &.product {
        width: 200px;
        height: 300px;
        border-radius: 14px;
        background-color: #fff;
        box-shadow : 0 3px 6px rgba(0,0,0, .16);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        position: relative;
        cursor: pointer;

        & .title, & .sub_title {
            font-size: 13px;
            font-weight: 600;
            color: #fff;
            text-shadow: 0 3px 5px rgba(0,0,0,.69);
            position: absolute;
            bottom: 0;
            left: 22px;
            z-index: 2;

            &.title {
                bottom: 24px;
            }
        }

        & .card_image {
            width: 100%;
            height: 100%;
            background: #414141;
            position: absolute;
            border-radius: 14px;
        }

        &.add_new {
            & a {
                color: $-blue;
                font-size: 14px;
            }
        }
    }

    &:active {
        transform: scale(.9);
    }

    &:hover {
    }
}