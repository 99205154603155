@import "../lavenst.scss";

.overview {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    flex-direction: row;

    @include mobile {
        flex-direction: column;
        margin-top: 48px;

        & .card {
            width: calc(80% - 96px);
            margin-top: 16px;
        }
    }

    @include tablet {
        justify-content: center;
        margin-top: 48px;

        & .card {
            width: calc(80% - 96px);
            margin-top: 16px;
        }
    }
}

.chart_container {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}