@import "../common/variables";


.tab_bar {
    width: 100%;
    min-width: 844px;
    display: flex;
    align-items: ceter;

    & .tab {
        background-color: #fff;
        padding: 0 32px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.disabled {
            opacity: .3;
        }

        &.active {
            background: $-blue;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;

            & .tab_title {
                color: #fff;
                text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
            }
        }

        & .tab_title {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.tab_content_container {
    width: 100%;
    min-width: 844px;

    &.cham_cong_table {
        @include mobile {
            display: flex;
        }
    }

    & .tab_content_header_container {
        background-color: $-blue;
        padding: 16px 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &.cham_cong_table {
            @include mobile {
                display: block;
                width: 77px;
                padding: 16px;

                & h1 {
                    left: 0;
                    position: relative;
                    font-size: 14px;
                    height: 64px;
                }

                & .tab_header_right_item_container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    & span {
                        margin-top: 0!important;
                        height: 64px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        & .tab_content_header{
            font-size: 18px;
            color: #fff;
            text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
            margin: 0;
            position: absolute;
            left: 32px;
        }

        & .tab_header_right_item_container {
            & .tab_header_item_title {
                margin:0 32px;
                color: #fff;
                font-size: 14px;
            }

            &.cham_cong_table {
                @include mobile {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    & .tab_header_item_title {
                        text-align: center;
                        margin-top: 38px;
                    }
                }
            }
        }
    }

    & .tab_content {
        width: 100%;
        display: flex;
        padding: 0 16px 16px 16px;

        &.cham_cong_table {
            @include mobile {
                display: flex;
                flex-direction: column;
                width: 250px;

                & .tab_side_bar {
                    flex-direction: row;
                    height: 79px;

                    & .tab_side_bar_title {
                        flex: 1;
                        margin-left: 40px;
                    }
                }

                & .tab_tick_container {
                    justify-content: flex-start;
                    width: 250px;

                    & .tab_tick {
                        margin: 0;
                        margin-left: 0!important;
                        display: block;
                        flex-direction: column;
                        margin-top: 0!important;

                        & .column {
                            flex-direction: row;
                            justify-content: center;
                            height: 64px;

                            & .checkbox {
                                margin-top: 0!important;
                                height: 17px;
                            }
                        }
                    }
                }
            }
        }

        & .tab_side_bar {
            width: 250px;
            display: flex;
            flex-direction: column;

            & .tab_side_bar_title {
                font-size: 15px;
                font-weight: 600;
                color: #414141;
                margin-left: 18px;
                margin-top: 32px;
                opacity: .75;
            }

            & .tab_side_bar_item {
                font-size: 15px;
                font-weight: 600;
                color: #414141;
                margin-left: 40px;
                margin-top: 16px;
                white-space: nowrap;
            }
        }

        & .tab_tick_container {
            width: 100%;
            display: flex;
            justify-content: center;

            & .tab_tick {
                width: 437px;
                margin-left: -214px;
                margin-top: 22px;
                display: flex;
                justify-content: center;
    
                & .column {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
    
                    & .checkbox {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 35px;

                        &:first-child {
                            margin-top: 28px;
                        }

                        &:nth-child(5), &:nth-child(7), &:nth-child(9) {
                            margin-top: 48px;
                        }
                    }
                }
            }
        }
    }
}