.view {
    margin-left: 128px;
    width: calc(100vw - (128px));
    padding-top: 128px;
    overflow: auto;

    @include mobile {
        margin-left: 0;
        width: 100%;
        padding-top: 96px;
    }

    @include tablet {
        margin-left: 0;
        width: 100%;
        padding-top: 96px;
    }

    &.bill_print{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        padding:0;

        @include mobile {
            width: calc(100%  - 32px);
            margin-left: 16px;
            padding-bottom: 32px;
        }

        & span {
            font-weight: 500;
            color: #000;
            margin-top: 16px;
            width: 100%;
            position: relative;
            font-weight: 600;

            & span {
                position: absolute;
                right: 0;
                width: auto;
                display: inline-block;
                margin: 0;
                font-weight: 500;
            }
        }

        & hr {
            width: 100%;
            height: 1px;
            border: none;
            background: #000;
            margin-top: 16px;
        }

        & table {
            width: 100%;
            margin-top: 8px;

            & tr {
                & th {
                    text-align: left;
                    height: 32px;

                    &:last-child {
                        text-align: right;
                    }
                }

                & td {
                    height: 32px;

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }

        & button {
            margin-top: 32px;
        }
    }

    &.center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .salary_container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & .salary_header {
            width: calc(100vw - (96px + 252px));
            height: 48px;
            background-color: rgba(255, 255, 255, 0.88);
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.25) 0, rgba(255, 255, 255, 0) 100%);
            backdrop-filter: saturate(50%) blur(20px);
            position: sticky;
            display: flex;
            align-items: center;
            top: 112px;
            z-index: 99999;

            & h1 {
                font-size: 20px;
                color: #2484FF;
                text-shadow: 0 3px 6px rgba(36, 131, 255, 0.281);
                font-weight: 500;
                text-decoration: none;
                margin-left: 48px;
            }
        }
    }
}

.login_view {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & h1{
        margin: 0;
        margin-bottom: 32px;
    }
}