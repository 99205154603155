.textbox {
    flex: 1!important;
    border: 1px solid #ced4da!important;
    padding: .375rem .75rem!important;
    transition: all .15s ease!important;
    max-width: 240px;
    border-radius: 4px!important;
    font-family: $-default-font!important;
    font-weight: 500!important;

    &:focus {
        color: #495057!important;
        background-color: #fff!important;
        border-color: #80bdff!important;
        outline: 0!important;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25)!important;
    }
}

textarea.textbox {
    transition: none!important;
    height: 300px!important;
    max-height: unset!important;
}

.box.textarea {
    height: 126px!important;
}

.search_box {
    transition: all .15s ease!important;
}

.search_box:focus-within {
    color: #495057!important;
    background-color: #fff!important;
    //border: 1px solid  #80bdff;
    outline: 0!important;
    //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}