@import "../../styles/common/variables";
@import "../lavenst.scss";

.header_bar{
    width: calc(100vw - (128px));
    height: 112px;
    background-color: rgba(255,255,255,.88);
    background-image: linear-gradient(to bottom,rgba(255,255,255,.25) 0,rgba(255,255,255,0) 100%);
    backdrop-filter: saturate(50%) blur(20px);
    -webkit-backdrop-filter: saturate(50%) blur(20px);
    z-index: $-menu-z-index;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: row;

    & .full_search_box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        & .icon {
            margin-left: 24px;

            &.close {
                margin-right: 16px;
            }
        }

        & input {
            flex: 1;
            margin-left: 24px;
        }

        & .autocomplete_container {
            width: calc(100% - 32px);
            position: absolute;
            box-shadow: 0 0 10px rgba(0,0,0,0.15);
            top: 64px;
            background: #fff;
            border-radius: 8px;

            & ul {
                width: calc(100% - 8px);
                height: calc(100% - 8px);
                margin: 16px;
                position: relative;
                list-style-type: none;
                padding: 0;

                & li {
                    width: calc(100% - 48px);
                    margin: 16px;
                    border-bottom: 1px solid #eaeaea;
                    padding-bottom: 14px;
                    cursor: pointer;
                    height: auto;

                    &:last-child {
                        border: none;
                        padding-bottom: 0;
                    }

                    & a {
                        background: transparent;
                        text-decoration: none;
                        font-size: 12px;
                        white-space: pre-wrap;
                        margin: 0;
                        padding: 0;
                        height: auto;
                        color: #414141!important;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    & .sidebar {
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999999;
        overflow: auto;

        & .close_btn {
            width: 64px;
            height: 64px;
            font-size: 18px;
        }

        & ul {
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0;

            & li {
                padding: 16px 0;

                &:first-child {
                    padding-top: 0;
                }

                & a {
                    color: #414141;
                    text-decoration: none;
                    font-size: 18px;
                }
            }
        }
    }

    @include mobile {
        width: 100%;
        height: 64px;
    }

    @include tablet {
        width: 100%;
        height: 64px;
    }

    & .side_bar_btn {
        font-size: 18px;
        height: 64px;
        width: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .dropdown_group {
        position: relative;
    }

    & .dropdown {
        width: 200px;
        background-color: #000;
        position: absolute;
        top: 48px;
        right: 8px;
        background-color: #fff;
        -webkit-box-shadow: 0 0 15px rgba(0,0,0,.15), 0 0 1px 1px rgba(0,0,0,.1);
        box-shadow: 0 0 15px rgba(0,0,0,.15), 0 0 1px 1px rgba(0,0,0,.1);
        padding: 0px 16px 16px 16px;
        border-radius: 8px;

        @include tablet {
            height: 70vh;
            overflow: auto;
            max-height:332px;
        }

        &:after { 
            content: ''; 
            display: inline-block; 
            border-left: 6px solid transparent; 
            border-right: 6px solid transparent; 
            border-bottom: 6px solid #fff; /* change color here, modified for a black arrow */ 
            position: absolute; 
            top: -6px; 
            right: 24px; 
            background: #fff;
            border: 1px solid #fff;
            bottom: -6px;
            -webkit-box-shadow: 0 0 5px 1px rgba(0,0,0,.0975);
            box-shadow: 0 0 5px 1px rgba(0,0,0,.0975);
            height: 14px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            width: 14px;
            z-index: -1;
        } 

        & .box {
            display: flex;
            flex-direction: column;

            & .btn.blue {
                background: #2484FF!important;
                color: #fff!important;
                margin: 0;
                margin-top: 24px;
            }

            & .title {
                margin-top: 16px;
                margin-bottom: 4px;
                font-size: 12.5px;
                font-weight: 600;
                color: #696969;
            }

            & .textbox {
                height: 31px;

                &.calendar {
                    width:174px;
                }
            }

            & select {
                margin-top: 8px;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            & .group {
                display: flex;
                align-items: center;
                justify-content: center;

                & .textbox {
                    height: 20px;
                }

                & .title {
                    margin: 0 8px;
                }
            }
        }
    }

    & h1 {
        font-size: 20px;
        color: $-blue;
        text-shadow: 0 3px 6px rgba(36, 131, 255, 0.281);
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .15s ease;

        @include mobile {
            display: none;
        }

        @include tablet {
            display: none;
        }
        
        &.back {
            cursor: pointer;

            & a {
                font-size: 20px;
                color: $-blue;
                text-shadow: 0 3px 6px rgba(36, 131, 255, 0.281);
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;

                & .icon {
                    margin-right: 8px;
                }
            }

            &:active{
                opacity: .55;
            }
        }
    }

    & .right_tools_container {
        position: relative;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-right: 56px;

        @include mobile {
            margin-right: 0;
        }

        @include tablet {
            margin-right: 0;
        }

        & .right_tools_box {
            position: absolute;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;

            & button, a {
                height: 32px;
                padding: 8px 16px;
                display: flex;
                align-items: center;
                flex-direction: row;
                margin: 0 10px;
                background: $-white-2;
                color: $-dark-4;
                font-weight: 500;
                border-radius: 6px;
                outline: none;
                border: none;
                cursor: pointer;
                white-space: nowrap;

                @include mobile {
                    background: transparent;
                    color: #000;
                    padding: 8px 10px;
                }

                @include tablet {
                    background: transparent;
                    color: #000;
                    padding: 8px 10px;
                }

                &.link_btn {
                    font-size: 13px;
                    text-decoration: none;
                    height: auto;
                }

                & .icon {
                    color: $-dark-4;
                    margin-right: 6px;

                    @include mobile {
                        font-size: 18px;
                        margin: 0;
                    }

                    @include tablet {
                        font-size: 18px;
                        margin: 0;
                    }
                }
            }

            & .search_box {
                height: 32px;
                padding: 0 16px;
                border-radius: 6px;
                background: $-white-2;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                & .autocomplete_container {
                    width: 100%;
                    position: absolute;
                    box-shadow: 0 0 10px rgba(0,0,0,0.15);
                    top: 48px;
                    background: #fff;
                    border-radius: 8px;

                    & ul {
                        width: calc(100% - 8px);
                        height: calc(100% - 8px);
                        margin: 16px;
                        position: relative;
                        list-style-type: none;
                        padding: 0;

                        & li {
                            width: calc(100% - 48px);
                            margin: 16px;
                            border-bottom: 1px solid #eaeaea;
                            padding-bottom: 14px;
                            cursor: pointer;
                            height: auto;

                            &:last-child {
                                border: none;
                                padding-bottom: 0;
                            }

                            & a {
                                background: transparent;
                                text-decoration: none;
                                font-size: 12px;
                                white-space: pre-wrap;
                                margin: 0;
                                padding: 0;
                                height: auto;
                            }
                        }
                    }
                }

                @include mobile {
                    display: none;
                }

                @include tablet {
                    display: none;
                }

                & .icon {
                    color: $-dark-4;
                }

                input {
                    max-width:  152px;
                    font-size: 13px;
                    background: transparent;
                    margin-left: 13px;
    
                    &::placeholder {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}